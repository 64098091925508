.form-container {
    width: 400px;
    background: linear-gradient(#212121, #212121) padding-box,
                linear-gradient(145deg, transparent 35%,#e81cff, #40c9ff) border-box;
    border: 2px solid transparent;
    padding: 32px 24px;
    font-size: 14px;
    font-family: inherit;
    color: white;
    display: flex;
    flex-direction: column;
    gap: 20px;
    box-sizing: border-box;
    border-radius: 16px;
  }
  
  .form-container button:active {
    scale: 0.95;
  }
  
  .form-container .form {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  
  .form-container .form-group {
    display: flex;
    flex-direction: column;
    gap: 2px;
  }
  
  .form-container .form-group label {
    display: block;
    margin-bottom: 5px;
    color: #717171;
    font-weight: 600;
    font-size: 12px;
  }
  
  .form-container .form-group input {
    width: 100%;
    padding: 12px 16px;
    border-radius: 8px;
    color: #fff;
    font-family: inherit;
    background-color: transparent;
    border: 1px solid #414141;
  }
  
  .form-container .form-group textarea {
    width: 100%;
    padding: 12px 16px;
    border-radius: 8px;
    resize: none;
    color: #fff;
    height: 96px;
    border: 1px solid #414141;
    background-color: transparent;
    font-family: inherit;
  }
  
  .form-container .form-group input::placeholder {
    opacity: 0.5;
  }
  
  .form-container .form-group input:focus {
    outline: none;
    border-color: #e81cff;
  }
  
  .form-container .form-group textarea:focus {
    outline: none;
    border-color: #e81cff;
  }
  
  .form-container .form-submit-btn {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    align-self: flex-start;
    font-family: inherit;
    color: #717171;
    font-weight: 600;
    width: 40%;
    background: #313131;
    border: 1px solid #414141;
    padding: 12px 16px;
    font-size: inherit;
    gap: 8px;
    margin-top: 8px;
    cursor: pointer;
    border-radius: 6px;
  }
  
  .form-container .form-submit-btn:hover {
    background-color: #fff;
    border-color: #fff;
  }
  
  

 
.chatBot{
  position: fixed;
  bottom: 0;
  right: 0;
  width: auto;
  z-index: 20;
}

.rsc-ts-bubble{
  max-width: 75% !important;
}

.font-header{
  color: white;
  font-size: medium;
  padding: 0.5%;
  display: flex;
  align-items: center;
}


.form{
  background-color: '#5658DD';
}