.box-block {
  display: block;
  width: 100%;
}

.ml-3 {
  margin-left: 3%;
}

.text-center {
  text-align: center;
}

.ml-auto {
  margin-left: auto;
}

.mr-auto {
  margin-right: auto;
}

.container-layout {
  padding-left: 8%;
  padding-right: 8%;
}


.fundacion-logo{
  background-color: #0088ff;
  padding: 3%;
}
.img-circle{
  border-radius: 50%;
  position: absolute;
  left: 0;
  top: 50%;
  left: 50%; /* Align the left edge of the image to the center horizontally */
  transform: translate(-50%, 0%); /* Move the image by 50% of its own width and height */
  max-width: 100%;
  transition: opacity 0.5s ease;  /* Ensure the image does not exceed the width of the container */
}

.fade-out {
  opacity: 0; /* Set opacity to 0 to fade out */
}
