.typing-effect {
    display: block;
    font-family: monospace;
    white-space: nowrap;
    border-right: 4px solid;
    margin-right: auto;
    margin-left: auto;
    width: 38ch;
    animation: typing 2s steps(12),
        blink .5s infinite step-end alternate;
    overflow: hidden;
}

@keyframes typing {
    from {
        width: 0
    }
}

@keyframes blink {
    50% {
        border-color: transparent;
    }
}