/* 3D tower loader made by: csozi | Website: www.csozi.hu*/

.loader {
    scale: 2.5;
    height: 50px;
    width: 40px;
    position: absolute;
    right: 0px;
    top: 0;
  }
  
  .box {
    position: relative;
    opacity: 0;
    left: 10px;
  }
  
  .side-left {
    position: absolute;
    background-color: #286cb5;
    width: 19px;
    height: 5px;
    transform: skew(0deg, -25deg);
    top: 14px;
    left: 10px;
  }
  
  .side-right {
    position: absolute;
    background-color: #2f85e0;
    width: 19px;
    height: 5px;
    transform: skew(0deg, 25deg);
    top: 14px;
    left: -9px;
  }
  
  .side-top {
    position: absolute;
    background-color: #5fa8f5;
    width: 20px;
    height: 20px;
    rotate: 45deg;
    transform: skew(-20deg, -20deg);
  }
  
  .box-1 {
    animation: from-left 4s infinite;
  }
  
  .box-2 {
    animation: from-right 4s infinite;
    animation-delay: 1s;
  }
  
  .box-3 {
    animation: from-left 4s infinite;
    animation-delay: 2s;
  }
  
  .box-4 {
    animation: from-right 4s infinite;
    animation-delay: 3s;
  }
  
  @keyframes from-left {
    0% {
      z-index: 20;
      opacity: 0;
      translate: -20px -6px;
    }
  
    20% {
      z-index: 10;
      opacity: 1;
      translate: 0px 0px;
    }
  
    40% {
      z-index: 9;
      translate: 0px 4px;
    }
  
    60% {
      z-index: 8;
      translate: 0px 8px;
    }
  
    80% {
      z-index: 7;
      opacity: 1;
      translate: 0px 12px;
    }
  
    100% {
      z-index: 5;
      translate: 0px 30px;
      opacity: 0;
    }
  }
  
  @keyframes from-right {
    0% {
      z-index: 20;
      opacity: 0;
      translate: 20px -6px;
    }
  
    20% {
      z-index: 10;
      opacity: 1;
      translate: 0px 0px;
    }
  
    40% {
      z-index: 9;
      translate: 0px 4px;
    }
  
    60% {
      z-index: 8;
      translate: 0px 8px;
    }
  
    80% {
      z-index: 7;
      opacity: 1;
      translate: 0px 12px;
    }
  
    100% {
      z-index: 5;
      translate: 0px 30px;
      opacity: 0;
    }
  }


  @keyframes blinkCursor {
    50% {
      border-right-color: transparent;
    }
  }
  
  @keyframes typeAndDelete {
    0%,
    10% {
      width: 0;
    }
    45%,
    55% {
      width: 6.2em;
    } /* adjust width based on content */
    90%,
    100% {
      width: 0;
    }
  }
  
  .terminal-loader {
    border: 0.1em solid #333;
    background-color: #1a1a1a;
    color: #0f0;
    font-family: "Courier New", Courier, monospace;
    font-size: 1em;
    padding: 1.5em 1em;
    width: 30em;
    margin: 100px auto;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    border-radius: 4px;
    position: relative;
    overflow: hidden;
    box-sizing: border-box;
  }
  
  .terminal-header {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 1.5em;
    background-color: #333;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    padding: 0 0.4em;
    box-sizing: border-box;
  }
  
  .terminal-controls {
    float: right;
  }
  
  .control {
    display: inline-block;
    width: 0.6em;
    height: 0.6em;
    margin-left: 0.4em;
    border-radius: 50%;
    background-color: #777;
  }
  
  .control.close {
    background-color: #e33;
  }
  
  .control.minimize {
    background-color: #ee0;
  }
  
  .control.maximize {
    background-color: #0b0;
  }
  
  .terminal-title {
    float: left;
    line-height: 1.5em;
    color: #eee;
  }
  
  .text {
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    border-right: 0.2em solid green; /* Cursor */
    animation: typeAndDelete 4s steps(11) infinite,
      blinkCursor 0.5s step-end infinite alternate;
    margin-top: 1.5em;
  }
  